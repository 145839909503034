<template>
  <v-form lazy-validation v-model="valid" ref="form">
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="12" sm="12" md="3" lg="3" class="py-0">
            <v-subheader>Invoice# <span class="red--text">*</span></v-subheader>
          </v-col>
          <v-col cols="12" sm="12" md="3" lg="3" class="py-0">
            <v-autocomplete
              v-model="invoice"
              outlined
              dense
              flat
              background-color="white"
              :rules="[v => !!v || 'Invoice is required']"
              return-object
              @change="onChangeInvoice"
              :items="listInvoice"
              item-text="invoiceNumber"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Old Tax Serial Number</v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="3" lg="3">
            <span>{{ form.oldSerialNumber }}</span>
          </v-col>
          <v-col cols="8" sm="8" md="2" lg="2" v-if="invoice" class="py-0">
            <v-checkbox
              v-model="usedSame"
              @change="onChangeUsed"
              label="Use to new tax number"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>New Tax Serial Number</v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="1" lg="1" class="py-0">
            <v-select
              v-model="digitOne"
              outlined
              dense
              flat
              background-color="white"
              :items="arrayFronts"
              @change="onChangeDigit"
            ></v-select>
          </v-col>
          <v-col cols="8" sm="8" md="1" lg="1" class="py-0">
            <v-select
              v-model="digitTwo"
              outlined
              dense
              flat
              background-color="white"
              :items="arrayFrontNexts"
              @change="onChangeDigit"
            ></v-select>
          </v-col>
          <v-col cols="8" sm="8" md="3" lg="3" class="py-0" v-if="!usedSame">
            <v-autocomplete
              v-model="digitThree"
              outlined
              dense
              flat
              background-color="white"
              :items="taxNumbers"
              return-object
              @change="onChangeTax"
              :rules="[v => !!v || 'New Tax Number is required']"
              item-text="serialNumber"
            ></v-autocomplete>
          </v-col>
          <v-col cols="8" sm="8" md="3" lg="3" class="py-0" v-if="usedSame">
            <v-text-field
              v-model="invoice.serialNumber"
              outlined
              dense
              flat
              background-color="white"
              disabled
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="3" lg="3" class="py-0">
            <v-subheader>Remark <span class="red--text">*</span></v-subheader>
          </v-col>
          <v-col cols="12" sm="12" md="3" lg="3" class="py-0">
            <v-textarea
              v-model="form.remark"
              outlined
              flat
              background-color="white"
              :rules="[v => !!v || 'Remark is required']"
              rows="3"
              placeholder="state your reason here why change tax number...."
            ></v-textarea>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0" cols="12">
        <v-btn class="mr-4" @click="submit" color="primary">
          submit
        </v-btn>
        <v-btn @click="clear">
          clear
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "change-tax-number",
  computed: {
    ...mapState("taxNumber", ["taxNumbers"]),
  },
  data: () => ({
    defaultForm: {
      invoiceId: null,
      oldSerialNumber: "",
      newSerialNumber: "",
      oldTaxId: null,
      newTaxId: null,
      remark: "",
      type: "",
    },
    form: {},
    valid: true,
    arrayFronts: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
    arrayFrontNexts: ["0", "1"],
    digitOne: "01",
    digitTwo: "0",
    digitThree: null,
    invoice: null,
    usedSame: false,
    listInvoice: [],
  }),

  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.$store.commit("SET_LOADER", true);
        await this.$store
          .dispatch("taxNumber/change", this.form)
          .then(() => this.$store.commit("SET_LOADER", false))
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      }
    },
    clear() {
      this.form = Object.assign({}, this.defaultForm);
      this.invoice = null;
      this.digitThree = null;
      this.digitOne = "01";
      (this.digitTwo = "0"), (this.usedSame = false);
    },
    onChangeInvoice(val) {
      this.form.invoiceId = val.id;
      this.form.type = val.type;
      this.form.oldTaxId = val.taxId;
      this.form.oldSerialNumber = val.taxInvoiceSerialNumber;
    },
    onChangeTax(val) {
      this.form.newSerialNumber = this.digitOne + this.digitTwo + "." + val.serialNumber;
      this.form.newTaxId = val.id;
    },
    onChangeUsed(val) {
      if (val) {
        this.form.newSerialNumber = this.digitOne + this.digitTwo + "." + this.invoice.serialNumber;
        this.form.newTaxId = this.invoice.taxId;
      } else {
        this.digitThree = null;
      }
    },
    onChangeDigit(val) {
      if (val) {
        this.form.newSerialNumber = this.digitOne + this.digitTwo + "." + this.invoice.serialNumber;
        this.form.newTaxId = this.invoice.taxId;
      } else {
        this.form.newSerialNumber =
          this.digitOne + this.digitTwo + "." + this.digitThree.serialNumber;
        this.form.newTaxId = this.digitThree.id;
      }
    },
    async getInvoiceTaxes() {
      await this.$store.dispatch("taxNumber/getInvoiceTaxes").then(response => {
        this.listInvoice = response.data;
        this.$store.commit("SET_LOADER", false);
      });
    },
    async getInitialData() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([this.getInvoiceTaxes(), await this.$store.dispatch("taxNumber/getAll", false)])
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },

  mounted() {
    this.form = Object.assign({}, this.defaultForm);
    this.getInitialData();
  },
};
</script>

<style></style>
